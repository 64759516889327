import React from "react";
import { Routes, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import { observer } from "mobx-react";

import Landing from "../pages/Landing";
import Contact from "../pages/Contact";
import About from "../pages/About";
import Playlist from "../pages/Playlist";
import Settings from "../pages/Settings";
import LiveRequests from "../pages/LiveRequests";
import Template from "../pages/Template";
import AudienceRequests from "../pages/AudienceRequests";
import AudienceData from "../pages/AudienceData";
import PaymentSuccess from "../pages/PaymentSuccess";
import PaymentFailed from "../pages/PaymentFailed";
import ActivateUser from "../pages/ActivateUser";
import Search from "../pages/Search";
import Campaign from "../pages/Campaign";
import Tutorials from "../pages/Tutorials";

function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Landing show="login" />} />
      <Route path="/register" element={<Landing show="register" />} />
      <Route path="/verify" element={<Landing show="verify" />} />
      <Route
        path="/forgot-password"
        element={<Landing show="forgot-password" />}
      />
      <Route
        path="/reset-password"
        element={<Landing show="reset-password" />}
      />
      <Route path="/resend-email" element={<Landing show="resend-email" />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/search" element={<Search />} />
      <Route path="/tutorials" element={<Tutorials />} />

      <Route exact path="/activateUser/" element={<ActivateUser />} />

      <Route exact path="/playlist" element={<PrivateRoute />}>
        <Route exact path="/playlist" element={<Playlist />} />
      </Route>

      <Route exact path="/settings" element={<PrivateRoute />}>
        <Route exact path="/settings" element={<Settings />} />
      </Route>

      <Route exact path="/live-requests" element={<PrivateRoute />}>
        <Route exact path="/live-requests" element={<LiveRequests />} />
      </Route>

      <Route exact path="/templates" element={<PrivateRoute />}>
        <Route exact path="/templates" element={<Template />} />
      </Route>

      <Route
        exact
        path="/audience-requests/:id"
        element={<AudienceRequests />}
      />

      <Route exact path="/audience-data" element={<PrivateRoute />}>
        <Route exact path="/audience-data" element={<AudienceData />} />
      </Route>

      <Route exact path="/subscription/success" element={<PrivateRoute />}>
        <Route
          exact
          path="/subscription/success"
          element={<PaymentSuccess />}
        />
      </Route>

      <Route exact path="/subscription/cancel" element={<PrivateRoute />}>
        <Route exact path="/subscription/cancel" element={<PaymentFailed />} />
      </Route>

      <Route exact path="/campaign" element={<PrivateRoute />}>
        <Route exact path="/campaign" element={<Campaign />} />
      </Route>
    </Routes>
  );
}

export default observer(AppRouter);
