import React, { useEffect, useState, useRef } from "react";
import authStore from "../../store/auth";
import domtoimage from "dom-to-image";
import { observer } from "mobx-react";
import { toDataURL } from "../../utils/toDataUrl";
import { PLACEHOLDER_IMAGE } from "../../constants";
import { FaInstagramSquare, FaFacebookF } from "react-icons/fa";
import loadingStore from "../../store/loading";
import { BounceLoader } from "react-spinners";
const Poster = ({ theme }) => {
  const [image, setImage] = useState(null);
  const [userImage, setUserImage] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [hiddenClass, setHiddenClass] = useState("hidden");

  const ref = useRef(null);
  const hiddenRef = useRef(null);
  const {
    state: { user },
  } = authStore;

  const posterImage = {
    poster: process.env.PUBLIC_URL + "/assets/images/poster.svg",
    poster_white: process.env.PUBLIC_URL + "/assets/images/poster-svg.png",
  };

  const fetchUserImage = () => {
    toDataURL(user.image + "?x=1")
      .then((res) => {
        setUserImage(res);
      })
      .catch((error) => {
        console.error("Fetching image failed:", error.message);
      });
  };

  useEffect(() => {
    if (user && user.image) {
      fetchUserImage();
    }
    if (user) {
      setQrCode(
        "https://nextquestup.com/api/template/generateQR?url=https://nextquestup.com/audience-requests/" +
          user.userId
      );
    }
  }, [user]);

  const download = () => {
    // const container = document.getElementById("posterflayer");
    setHiddenClass("block");
    loadingStore.setLoading(true);
    const container = hiddenRef.current;
    domtoimage
      .toPng(container, {
        quality: 1,
        bgcolor: "#000000",
      })
      .then((dataUrl) => {
        const a = document.createElement("a");
        a.href = dataUrl;
        a.download = "generated-image.png";
        a.click();
        setHiddenClass("hidden");
      })
      .catch((error) => {
        setHiddenClass("hidden");
        console.error("Error generating image:", error);
      });
    loadingStore.setLoading(false);
  };

  useEffect(() => {
    if (user) {
      fetchUserImage();
    }
  }, [user]);

  let arr = user?.bandName.split(" ");
  let venmoName = user?.venmo.split(" ");

  return (
    <>
      {hiddenClass == "hidden" ? (
        <section className="flex justify-center py-5 flex-col items-center gap-5 ">
          <section className="flex flex-col gap-4 items-center justify-center">
            <section
              className={`w-[330px] relative px-4 h-[500px] bg-no-repeat flex flex-col justify-center items-center gap-4 xxl:gap-8 text-[12px] bg-contain bg-center ${
                theme ? "text-white" : "text-black"
              }`}
              style={{
                backgroundImage: `url(${
                  theme ? posterImage.poster : posterImage?.poster_white
                }) `,
              }}
              id="poster"
            >
              <section className="flex flex-col justify-center items-center py-2 gap-3 px-4">
                <img
                  className="rounded w-[90%] h-[150px] object-contain"
                  src={userImage ? userImage : PLACEHOLDER_IMAGE}
                  alt="profile"
                />
                <span className="font-semibold text-xl">
                  {user?.bandName && (
                    <>
                      {arr.map((name, index) => (
                        <span key={index}>{name}&nbsp;</span>
                      ))}
                    </>
                  )}
                </span>
                <div className="head flex justify-center">
                  <img
                    className="w-16 h-16 object-contain"
                    src={qrCode}
                    alt="QR"
                  />
                </div>
                <h5 className="font-semibold text-[10px]">
                  Make&nbsp;A&nbsp;Request
                </h5>
                <p className="text-[10px] text-center ">
                  Scan the QR code with your mobile device to view my song list.
                </p>
                <p className="text-[10px] text-center">
                  Click on a Song to send your NextQuest
                </p>

                {user.venmo && (
                  <span
                    className={`flex items-center justify-center text-center rounded overflow-hidden border-2 border-maroon ${
                      theme ? "text-white bg-gray" : "text-black bg-white"
                    } mt-1 px-2 p -1 border-red`}
                  >
                    &nbsp;&nbsp;<span className="font-bold">Venmo </span>&nbsp;
                    {user?.venmo && (
                      <span className="">
                        {venmoName.map((name, index) => (
                          <span key={index}>{name}&nbsp;</span>
                        ))}
                      </span>
                    )}
                    &nbsp;&nbsp;
                  </span>
                )}
              </section>
              <section className="text-[8px] w-[90%] gap-5 flex lg:px-3 justify-between items-center">
                {user.facebook && (
                  <span className="flex items-center gap-3 flex-1 ">
                    <FaFacebookF
                      size={24}
                      className="bg-[#1877F2] text-white w-5 h-5 p-1 rounded"
                    />
                    <p className="line-clamp-1 text-[10px]">
                      {user.facebookHandle}
                    </p>
                  </span>
                )}
                {user.instagram && (
                  <span className="flex items-center gap-3 flex-1 justify-end">
                    <FaInstagramSquare
                      size={24}
                      className="logo_color text-backgroundImage w-5 h-5 rounded text-white"
                    />
                    <p className="line-clamp-1 text-[10px]">
                      {user.instaHandle}
                    </p>
                  </span>
                )}
              </section>
            </section>
            <button className="bg-red p-2 rounded w-full" onClick={download}>
              DOWNLOAD
            </button>
          </section>
        </section>
      ) : (
        <div className="fixed w-full h-[100vh] top-0 left-0 bg-[rgba(0,0,0,0.95)] z-[777777777777777777] flex items-center justify-center">
          <BounceLoader color="gray" size={50} speedMultiplier={3} />
        </div>
      )}

      <section
        className={`flex  justify-center py-5 flex-col items-center gap-5 ${hiddenClass}`}
      >
        <section className="flex flex-col gap-4 items-center justify-center">
          <section
            className={`w-[3600px] relative h-[5400px] bg-no-repeat flex flex-col justify-center items-center gap-4 xxl:gap-8 text-[12px] bg-contain bg-center ${
              theme ? "text-white" : "text-black"
            }`}
            ref={hiddenRef}
            style={{
              backgroundImage: `url(${
                theme ? posterImage.poster : posterImage?.poster_white
              }) `,
            }}
            id="posterflayer"
          >
            <section className="flex flex-col justify-center items-center py-2 gap-3 px-4">
              <img
                className="rounded w-[90%] h-[1500px] object-contain"
                src={userImage ? userImage : PLACEHOLDER_IMAGE}
                alt="profile"
              />
              <span className="font-semibold  " style={{ fontSize: 300 }}>
                {user?.bandName && (
                  <>
                    {arr.map((name, index) => (
                      <span key={index}>{name}&nbsp;</span>
                    ))}
                  </>
                )}
              </span>
              <br />
              <div className="head flex justify-center ">
                <img
                  className="w-[600px] h-[600px] object-contain"
                  src={qrCode}
                  alt="QR"
                />
              </div>
              <br />
              <h5 className="font-semibold " style={{ fontSize: 200 }}>
                Make&nbsp;A&nbsp;Request
              </h5>

              <p className=" text-center " style={{ fontSize: 70 }}>
                Scan the QR code with your mobile device to view my song list.
              </p>

              <p className=" text-center min-w-[90%] " style={{ fontSize: 70 }}>
                Click on a Song to send your NextQuest
              </p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              {user.venmo && (
                <span
                  className={`flex items-center justify-center text-center rounded overflow-hidden border-2 border-maroon ${
                    theme ? "text-white bg-gray" : "text-black bg-white"
                  } px-2 p-1 border-red `}
                  style={{ fontSize: 70 }}
                >
                  &nbsp;&nbsp;<span className="font-bold">Venmo </span>&nbsp;
                  {user?.venmo && (
                    <span className="">
                      {venmoName.map((name, index) => (
                        <span key={index}>{name}&nbsp;</span>
                      ))}
                    </span>
                  )}
                  &nbsp;&nbsp;
                </span>
              )}
            </section>

            <section className="text-[8px] absolute bottom-52  w-[90%] gap-5 flex lg:px-3 justify-between items-center">
              {user.facebook && (
                <span className="flex items-center gap-10 flex-1 ">
                  <FaFacebookF
                    size={24}
                    className="bg-[#1877F2] text-white w-32 h-32 p-1 rounded"
                  />
                  <p className="line-clamp-1 " style={{ fontSize: 70 }}>
                    {user.facebookHandle}
                  </p>
                </span>
              )}
              {user.instagram && (
                <span className="flex items-center gap-10 flex-1 justify-end">
                  <FaInstagramSquare
                    size={24}
                    className="logo_color text-backgroundImage w-32 h-32 rounded text-white"
                  />
                  <p className="line-clamp-1 " style={{ fontSize: 70 }}>
                    {user.instaHandle}
                  </p>
                </span>
              )}
            </section>
          </section>
        </section>
      </section>
    </>
  );
};

export default observer(Poster);
