import React, { useEffect, useState, useRef } from "react";
import authStore from "../../store/auth";

import domtoimage from "dom-to-image";
import { observer } from "mobx-react";
import { toDataURL } from "../../utils/toDataUrl";
import { BounceLoader } from "react-spinners";

const ThirdFlyer = ({ enableState, setEnableState }) => {
  const [image, setImage] = useState(null);
  const [userImage, setUserImage] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [hiddenClass, setHiddenClass] = useState("hidden");
  const [existingImage, setExistingImage] = useState(null);
  const hiddenRef = useRef(null);
  const download = () => {
    // const container = document.getElementById("posterflayer");
    setHiddenClass("block");

    const container = hiddenRef.current;
    console.log("container ::::::", container);
    domtoimage
      .toPng(container, {
        quality: 1,
        // bgcolor: "#000000",
      })
      .then((dataUrl) => {
        const a = document.createElement("a");
        a.href = dataUrl;
        a.download = "generated-image.png";
        a.click();
        setHiddenClass("hidden");
      })
      .catch((error) => {
        setHiddenClass("hidden");
        console.error("Error generating image:", error);
      });
  };
  const {
    state: { user },
  } = authStore;

  const images = {
    back: process.env.PUBLIC_URL + "/assets/images/back.png",
    back_white: process.env.PUBLIC_URL + "/assets/images/back_white.png",
  };

  useEffect(() => {
    user &&
      setQrCode(
        "https://nextquestup.com/api/template/generateQR?url=https://nextquestup.com/audience-requests/" +
          user.userId
      );

    {
      enableState && download();
    }
    setEnableState(false);
    // }, [user, enableState]);
  }, [user, enableState]);

  const fetchImage = () => {
    const container = document.getElementById("printflayer");

    // container.style.backgroundColor = theme ? "black" : "white";
    // container.style.color = theme ? "white" : "black";
    // domtoimage
    //   .toPng(container, {
    //     quality: 1,
    //     bgcolor: "#ffffff",
    //     // width: 600,
    //     // height: 750,
    //     // border: "none",
    //   }) // Specify width and height here
    //   .then((dataUrl) => {
    //     setImage(dataUrl);
    //   })
    //   .catch((error) => {
    //     console.error("Error generating image:", error);
    //   });
  };

  useEffect(() => {
    fetchImage();
    // }, [userImage, theme]);
  }, [qrCode, enableState]);

  return (
    <>
      {hiddenClass == "hidden" ? (
        <section
          className={`${"text-black bg-white overflow-hidden"} w-[350px]   mt-4 rounded-lg  bg-center bg-contain px-4 h-[600px]   bg-no-repeat flex  flex-col items-center gap-4  text-[14px] justify-center `}
          // style={{
          id="print"
        >
          <section className="flex flex-col gap-2 w-full">
            <p className="font-semibold text-center xs:text-[34px] text-2xl">
              {user?.firstName}
              &nbsp;
              {user?.lastName}
            </p>
            <p
              className="overflow-hidden p-2 text-justify "
              style={{ textWrap: "pretty", wordBreak: "break-word" }}
            >
              {user?.bio}
            </p>
            {/* <p className="text-justify line-clamp-6 text-[13px] ">
          Once the value of the query parameter is obtained, it can be utilized
          within the React component as needed. This process enables dynamic
          behavior within the component based on the query parameters present in
          the URL. Whether it's customizing the disp
        </p> */}
          </section>
          <h6 className="font-semibold ">Make&nbsp;A&nbsp;Request</h6>
          <div className="head flex justify-center">
            <img className="w-28 h-28 object-contain" src={qrCode} alt="QR" />
          </div>
          <section className="w-[90%] flex flex-col gap-5 ">
            <p className="text-center text-[14px]">
              Scan the QR code with your mobile device to view my song list.
            </p>
            <p className="text-center text-[12px]">
              Click on a Song to send your Nextquest
            </p>
          </section>
        </section>
      ) : (
        <div className="fixed w-full h-[100vh] top-0 left-0 bg-[rgba(0,0,0,0.95)] z-[777777777777777777] flex items-center justify-center">
          <BounceLoader color="gray" size={50} speedMultiplier={3} />
        </div>
      )}

      <section className={` ${hiddenClass}`}>
        <section
          className={`${"text-black bg-white overflow-hidden"} w-[600px]   mt-4 rounded-lg  bg-center bg-contain px-4 h-[750px]   bg-no-repeat flex  flex-col items-center gap-6  text-[14px] justify-center`}
          // style={{
          id="printflayer"
          ref={hiddenRef}
        >
          <section className="flex flex-col gap-6 w-full">
            <p className="font-bold text-center  text-5xl">
              {user?.firstName}
              &nbsp;
              {user?.lastName}
            </p>
            <p
              className="overflow-hidden p-2 text-justify text-xl "
              style={{ textWrap: "pretty", wordBreak: "break-word" }}
            >
              {user?.bio}
            </p>
            {/* <p className="text-justify line-clamp-6 text-[13px] ">
          Once the value of the query parameter is obtained, it can be utilized
          within the React component as needed. This process enables dynamic
          behavior within the component based on the query parameters present in
          the URL. Whether it's customizing the disp
        </p> */}
          </section>
          <h6 className="font-semibold text-2xl">Make&nbsp;A&nbsp;Request</h6>
          <div className="head flex justify-center">
            <img className="w-40 h-40 object-contain" src={qrCode} alt="QR" />
          </div>
          <section className="w-[90%] flex flex-col gap-5 ">
            <p className="text-center text-xl">
              Scan the QR code with your mobile device to view my song list.
            </p>
            <p className="text-center text-xl">
              Click on a Song to send your Nextquest
            </p>
          </section>
        </section>
      </section>
    </>
  );
};

export default observer(ThirdFlyer);
